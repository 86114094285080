<template>
  <div>
    <!-- <page-header :tabsItems="tabsItems" :title="title"></page-header> -->
    <v-container fluid v-if="!loaded">
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile class="py-2">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-card>
          <v-card outlined tile class="py-5">
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-form ref="form" lazy-validation>
        <v-row justify="center">
          <v-col sm="12" md="10" lg="10">
            <v-card flat>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="12" class="px-0">
                    <v-alert
                      v-if="form.ipAddr == '0.0.0.0'"
                      dense
                      border="left"
                      type="warning"
                    >
                      학원의 공인IP를 등록하지 않으면, 학생들은 클래스아이를 이용할 수 없습니다.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-toolbar>
            </v-card>
            <v-card outlined tile>
              <v-card-title>
                학원 기본정보
                <v-spacer/>
                <v-btn text color="secondary" v-if="edit" @click="cancel">
                  취소
                </v-btn>
                <v-btn tile outlined color="primary" class="ml-3" @click="editSave">
                  {{edit ? '저장' : '수정'}}
                </v-btn>
              </v-card-title>
              <v-card-subtitle>학생은 학원에서만 클래스아이에 접속할 수 있습니다.</v-card-subtitle>
              <v-divider/>
              <v-row v-if="!edit" class="my-2">
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="caption">학원명</v-list-item-subtitle>
                      <v-list-item-title>{{ form.name ? form.name : '학원명을 입력하세요' }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="caption">학원유형</v-list-item-subtitle>
                      <v-list-item-title>{{ form.type ? form.type : '학원유형을 입력하세요' }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>원생별 스티커 갯수<v-icon dense color="red darken-2">mdi-stamper</v-icon></v-list-item-subtitle>
                      <v-list-item-title>{{ form.stickerCnt ? form.stickerCnt : 0 }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>스티커 완료 후 포인트 보상<v-icon dense color="teal darken-2">mdi-file-powerpoint-box</v-icon></v-list-item-subtitle>
                      <v-list-item-title>{{ form.stickerReward ? numberWithComma : 0 }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>학원의 공인IP 등록</v-list-item-subtitle>
                      <v-list-item-title class="warning--text text-body-2">{{ form.ipAddr ? form.ipAddr : '학원 IP를 등록하셔야 학생이 접속할 수 있습니다.' }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row v-else class="my-2">
                <v-col cols="12" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="form.name"
                        label="학원명"
                        :rules="[rule.required]"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        :items="categoryItems"
                        label="학원유형"
                        :rules="[rule.required]"
                        v-model="form.type"
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="form.stickerCnt"
                        label="원생별 최대 스티커 갯수"
                        :rules="[rule.required, rule.numberOnly1]"
                        hint="학생에게 최대 스티커 갯수를 설정할 수 없습니다"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="form.stickerReward"
                        label="스티커 완료 후 포인트 보상"
                        :rules="[rule.required, rule.numberOnly2]"
                        hint="학생이 최대 스티커 갯수를 채우면 포인트만큼 보상합니다"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="form.ipAddr"
                        label="학원의 공인IP 등록"
                        :rules="[rule.required, rule.ipAddr]"
                        hint="학원의 공인IP를 등록하세요"
                        autocomplete="off"
                      ></v-text-field>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn outlined tile color="green" v-if="edit" @click="getAddr">학원IP 가져오기</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>
      </v-form>

    </v-container>

  </div>
</template>
<script>
// import PageHeader from '../PageHeader'

export default {
  // components: { PageHeader },
  data () {
    return {
      edit: false,
      title: '학원관리',
      tabsItems: [
        { id: 1, title: '학원정보', link: '/manage/academy' },
        { id: 2, title: '학생관리', link: '/manage/students' },
        { id: 3, title: '포인트', link: '/manage/point' },
        { id: 4, title: '즐겨찾기', link: '/manage/links' }
      ],
      categoryItems: [
        '학원', '교습소', '공부방', '기타'
      ],
      rule: {
        required: v => !!v || '필수 항목입니다.',
        numberOnly1: v => {
          v = Number(v)
          const pattern = /^[1-9][0-9]?$|^100$/
          return pattern.test(v) || '100 이하 숫자만 입력하세요.'
        },
        numberOnly2: v => {
          v = Number(v)
          const pattern = /^[1-9][0-9]{0,4}?$|^100000$/
          return pattern.test(v) || '100,000 이하 숫자만 입력하세요.'
        },
        ipAddr: v => {
          const pattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
          return pattern.test(v) || 'IP 주소 형식이 아닙니다.'
        }
      },
      form: {
        name: '',
        type: '',
        stickerCnt: 50,
        stickerReward: 100,
        ipAddr: '0.0.0.0',
        createdAt: new Date()
      },
      loaded: false
    }
  },
  computed: {
    numberWithComma () {
      const x = this.form.stickerReward
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted () {
    this.read()
  },
  methods: {
    async editSave () {
      let academyid = ''
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }

      if (this.edit) {
        if (!this.$refs.form.validate()) return
        // 왼쪽 0 제거
        this.form.stickerCnt = Number(this.form.stickerCnt)
        this.form.stickerReward = Number(this.form.stickerReward)

        // 변경된 스티커 갯수 - 1 확인
        const sn = await this.$firebase.firestore().collection('students')
          .where('academy', '==', academyid)
          .where('stickerCnt', '>', this.form.stickerCnt - 1).get()

        if (sn.size > 0) {
          this.$toast.error('스티커 갯수가 ' + this.form.stickerCnt + '개 이상인 학생이  ' + sn.size + '명 있습니다')
          return
        }

        await this.$firebase.firestore().collection('academies').doc(this.$store.state.user.uid).set(this.form)
        this.$store.dispatch('getTitle', this.form.name)
        this.$store.dispatch('getTotStickerCnt', this.form.stickerCnt)

        localStorage.stickerTot = this.form.stickerCnt
        localStorage.stickerReward = this.form.stickerReward
      }

      this.edit = !this.edit
    },
    async read () {
      this.loaded = false
      let academyid = ''
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }
      const r = await this.$firebase.firestore().collection('academies').doc(academyid).get()
      if (r.exists) {
        this.form = r.data()
      }
      this.loaded = true
    },
    cancel () {
      this.read()
      this.edit = !this.edit
    },
    getAddr () {
      // 공인IP 사이트 : https://extreme-ip-lookup.com/json   https://api.ip.pe.kr/json/    https://ipstack.com

      this.$http.get('https://api.ip.pe.kr/json/')
        .then(r => {
          if (r.status === 200 && r.ok) {
            // console.log(r.body.ip)
            // console.log(r.body.country_name.en)
            this.form.ipAddr = r.body.ip
          }
        })
        .catch(e => {
          this.$store.commit('pop', { msg: '인터넷에 연결할 수 없습니다', color: 'warning' })
        })
    }

  }
}
</script>
